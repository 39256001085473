@media only screen and (min-device-width: 319px) and (max-device-width: 340px) 
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71){
  /* iPhone 5 only */
  .middleBox{
    padding-top:15rem;
    width:3rem !important;
    /* padding-top:0rem !important; */
    min-height: 1rem !important;
    max-height: 1rem;
  }
}

#app, #app>div, #root, body, html {
  width:100% !important;
  height: 100% !important;
}

body{
  width: 22rem;
  height: 34rem !important;
}

.eventContainer{
  top: 50% !important;
  margin: 0;
  left: 50%;
  position: absolute;
  transform: translateY(-50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.landingPage{
  height:100%;
}
.erezLogo{
  width:22rem;
  margin:auto;
  background-color:#FFFFFF99;
  /* width: 5rem;
  bottom: 0;
  margin-left: 8.5rem !important;
  margin-right: 8.5rem !important; */
}
.erezLogo img{
  display:block;
  width:5rem;
  height:4.5rem !important;
  background-color:#FFFFFF00 !important;
  margin:auto;
}

/* background: url(https://firebasestorage.googleapis.com/v0/b/room-picker.appspot.com/o/bg_1.jpg?alt=media&token=9f64816e-922e-439e-b95c-c699610be252) no-repeat center center fixed;  */
.landingPageEvent{
  background: url(../images/bg_4.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height:100%;
}

/* Reminder : background image 2 taken from https://oxleytravel.com.au/the-kids-cant-wait/ */
.middleBox{
  min-height: 37.5rem !important;
  max-height: 37.5rem;
  display: block !important;
  margin: auto !important;
  width:22rem !important;
  padding-top: 2rem;
  /* border-right:1px solid black;
  border-left:1px solid black; */
  background-color:#FFFFFF99;
}

.idInput{
  width: 16rem !important;
  margin:auto !important;
  display:block !important;
  margin-top:7rem !important;
  border:1px solid black !important;
}

input[type="id"]::placeholder { /* Firefox, Chrome, Opera */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
} 

input[type="email"]:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color: black;
  text-align:center; 
  opacity: 0.5;
  font-weight: 500;
} 

input[type="email"]::-ms-input-placeholder { /* Microsoft Edge */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
} 

.enterBtn{
  display: block !important;
  margin: auto;
  margin-top: 1rem;
}

html, body {
    height: 100% !important;
}

/* html, body, #app, #app>div, #root{
  height: 100%;
  width:100%;
} */

.appBody{
  height: 100% !important;
}

.CompanyLogo{
  margin: auto;
  display: block;
  min-height: 4rem;
  max-height: 4rem;
  width: 10rem;
}

h1.eventName{
  width: auto;
  text-align:center !important;
  color:black;
  /* font-weight: bolder; */
  margin-top:0rem !important;
  padding-top:0.5rem !important;
  margin-bottom:0rem !important;
  font-family: 'Heebo', sans-serif;
}

.thinking{
  display:block;
  margin:auto;
}
.workerSelctor{
width: 16rem;
margin: auto !important;
display: block !important;
text-align: center;
top: 1rem !important;
}

.ant-select-selection__placeholder{
text-align:center !important;
}

.workerSave{
  width: 5rem;
  margin: auto !important;
  display: block !important;
  top: 2rem !important;
}
.workerInputs{
  top:0.7rem !important;
  width: 16rem !important;
  margin: auto !important;
  display: block !important;
  text-align:center;
}
.divInputs{
  padding-bottom: 0.45rem !important;
}
.chooseRoomate{
  margin-top: -1rem;
}
.heyWorker{
  font-family: 'Heebo', sans-serif;
  text-align:center;
  color:black !important;
  margin-top:0.5rem !important;
}
.fillDetails{
  font-family: 'Heebo', sans-serif;
  font-size: 0.9rem !important;
  text-align: left;
  color: black !important;
  margin-left: 3.08rem !important;
  margin-bottom: 0px !important;
  margin-top: 0.2rem;
}
.pleaseChoose {
  margin-bottom:0rem !important;
  font-family: 'Heebo', sans-serif;
  font-size: 0.9rem !important;
  text-align: left;
  color: black !important;
  margin-left: 3.08rem !important;
}

#emailUp{
  margin-top: -0.7rem !important;
}

.titleCheckboxs{
  margin: auto !important;
  text-align: center;
  
  margin-top: 0rem !important;
}
.titleCheckboxs label{
  color:black !important;
   
}

.pickedBySomeoneElse .fillDetails{
  font-family: 'Heebo', sans-serif;
  font-size: 0.9rem !important;
  text-align: left;
  color: black !important;
  margin-left: 3.08rem !important;
  margin-bottom: -0.4rem !important;
  margin-top: 0.5rem !important;
}

.pickedBySomeoneElse .titleCheckboxs{
  margin: auto !important;
  text-align: center;
  /* margin-left: 3.1rem !important; */
  margin-top: 0rem !important;
}

.pickedBySomeoneElse .titleCheckboxs label{
  color:black !important;
}


.ant-checkbox + span {
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.thinking{
  margin:auto;
  width:1rem;
  display: block;
  margin-top:19rem !important;
}
.ant-select-selection-selected-value {
  float: none !important;
}

/* for hiiden originaly inputNumber arrows  */
.ant-input-number-handler-wrap{
  visibility: hidden !important;
}

.cencelArea{
  font-size:1.4rem;
  /* color:mediumblue; */
  color:black;
  text-align:center;
  margin-top: 6rem;
}
.cencelAreaPicked{
  font-size:1.4rem;
  /* color:mediumblue; */
  color:black;
  text-align:center;
  margin-top: 1rem;
}
.cencelLine{
    display: block;
    /* display: contents; */
    font-family: 'Heebo', sans-serif;
    font-size: 1.3rem ;
    color: black ;
    font-weight: 900;
    /* position: relative; */
    margin-top: 11rem ;
}
.pickedBySomeoneElse .cencelLine{
  display: block;
  /* display: contents; */
  font-family: 'Heebo', sans-serif;
  font-size: 1.3rem ;
  color: black ;
  font-weight: 900;
  /* position: relative; */
  margin-top: 4rem ;
}

.cencelLine Button{
  top:0.4rem !important;
}

.ant-alert-with-description i{
  display: contents;
}

.ant-alert-with-description{
  text-align:center;
  top: 5rem;
}
.beenPickedText{
  margin-bottom:2rem;
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3){

}





@media screen and (device-aspect-ratio: 2/3) {
 
}



/* wide screen (not mobile!) */
@media screen and (min-height: 590px) and (max-height: 650px)
              and (min-width: 750px) and (max-width: 820px) 
              {
  .middleBox{
    min-height: 33.3rem;
    max-height: 33.3rem;
    min-width: 20rem;
    max-width: 20rem;
  }
  .cencelAreaPicked {
    margin-top:0rem !important;
  }
  .beenPickedText {
    margin-bottom:0rem !important;
  }
  .pickedBySomeoneElse .cencelLine{
    margin-top:3.5rem !important;
    font-size:0.9rem !important;
  }
  .erezLogo{
    width:20rem;
    height:4.5rem;
  }
  h1.eventName {
    margin-bottom:0.5rem !important;
  }
}
/* very wide screen (not mobile!) */
@media screen and (min-height: 900px) and (max-height: 1100px)
              and (min-width: 1500px) and (max-width: 2300px) 
              {
  .middleBox{
    min-height: 55rem;
    max-height: 55rem;
    min-width: 25rem !important;
    max-width: 25rem !important;
    padding-top:5rem;
  }
  .erezLogo{
    width:25rem;
    height:10rem;
  }
}

/* iphone SE/5 */
@media screen and (min-height: 567px) and (max-height: 569px)
              and (min-width: 319px) and (max-width: 330px) 
{
  
}

@media screen and (min-height: 660px) and (max-height: 674px)
              and (min-width: 370px) and (max-width: 380px) 
{
  .middleBox{
    min-height: 35rem !important;
    padding-top:1rem !important;
  }
}


@media screen and (min-height: 668px) and (max-height: 690px)
              and (min-width: 668px) and (max-width: 2500px) 
{
  .middleBox{
    min-height:33rem !important;
  }
}
@media screen and (min-height: 0px) and (max-height: 650px)
{
  .middleBox{
    min-height:25rem !important;
  }
  .workerSave{
    margin-top:-1rem !important;
  }
  .pickedBySomeoneElse .cencelLine{
    margin-top:2.5rem !important;
    font-size: 0.8rem !important;
  }
  .cencelAreaPicked {
    margin-top:0rem !important;
    height:20.5rem !important;
  }
  .beenPickedText {
    margin-bottom:0rem !important;
    font-size:1.1rem !important;
  }
  .CompanyLogo{
    min-height: 2rem !important;
    max-height: 3rem !important;
    width: 8rem !important;
  }
  .erezLogo img {
    height:3.5rem !important;
    width:3.5rem !important;
  }
}
/* @media screen and (min-width: 350px) and (max-width: 2500px)
  {
  #app, #app>div, #root, body, html {
    width:100% !important;
    height: 100% !important;
  }
} */