.header{
    width:100%;
    height: 4rem;
    background-color:black;
    color:white;
    text-align:center;
    line-height: 4rem;
    margin-bottom:2rem;
}

.header h1{
    margin: 0px;
    color:white;
}