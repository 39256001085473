.thankYouPage{
    display: block;
    text-align:center;
    font-size:1.2rem;
    color:black;
}

.thankYouPage .thankYou{
    text-align: center;
    /* font-size: x-large; */
    margin-top:2.5rem !important;
    color:black;
}

.thankYouPage .enjoy{
    text-align:center;
    color:black !important;
    font-size:2.4rem !important;

    
    /* font-size:4rem; */
}