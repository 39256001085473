.adminBody{
    width:80%;
    margin:auto;
}

.addEventBtn{
    width:100%;
    margin-top:-1rem;
}

.addEventBtn Button{
    display:block;
    margin:auto;
}

.adminBody h1{
    padding-top:1.5rem;
    text-align:center;
    /* font-weight: bolder; */
}

.logo{
    width: 2.5rem;
    /* border-radius: 1.1rem; */
}



/* .ant-table-thead  tr:first-child  th:last-child {
    width: 13rem;
}

.ant-table-thead  tr:first-child  th:first-child{
    width: 15rem;
}
.ant-table-thead  tr  th, .ant-table-tbody  tr  td{
    width: 13rem;
} */

.hidden{
    visibility:hidden;
}

h1{
    font-family: 'Heebo', sans-serif;
}

/* .ant-table-column-has-sorters{
    width:auto ;
} */
.timeStamp{
    min-width: 6.5rem !important;
    padding: 0px !important;
}

.companyName{
    width:9rem;
}
.eventName{
    width:10rem;
}
.url{
    width:6rem !important;
    text-align:center !important;
}
.action{
    min-width: 9.3rem !important;
    text-align:center !important;
    padding: 0px !important;
}
.activity{
    padding: 0 !important;
    text-align: center !important;
}
.prepare{
    min-width: 9.1rem !important;
    width:9.1rem;
    text-align:center !important;
}
.exportColumn{
    text-align:center !important;
    padding: 0 !important;
}
.endPeriodTime{
    min-width:7.3rem !important;
}