@import url(https://fonts.googleapis.com/css?family=Roboto:300);
body {
  margin: 0;
  font-family: 'Heebo', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header{
    width:100%;
    height: 4rem;
    background-color:black;
    color:white;
    text-align:center;
    line-height: 4rem;
    margin-bottom:2rem;
}

.header h1{
    margin: 0px;
    color:white;
}
.adminBody{
    width:80%;
    margin:auto;
}

.addEventBtn{
    width:100%;
    margin-top:-1rem;
}

.addEventBtn Button{
    display:block;
    margin:auto;
}

.adminBody h1{
    padding-top:1.5rem;
    text-align:center;
    /* font-weight: bolder; */
}

.logo{
    width: 2.5rem;
    /* border-radius: 1.1rem; */
}



/* .ant-table-thead  tr:first-child  th:last-child {
    width: 13rem;
}

.ant-table-thead  tr:first-child  th:first-child{
    width: 15rem;
}
.ant-table-thead  tr  th, .ant-table-tbody  tr  td{
    width: 13rem;
} */

.hidden{
    visibility:hidden;
}

h1{
    font-family: 'Heebo', sans-serif;
}

/* .ant-table-column-has-sorters{
    width:auto ;
} */
.timeStamp{
    min-width: 6.5rem !important;
    padding: 0px !important;
}

.companyName{
    width:9rem;
}
.eventName{
    width:10rem;
}
.url{
    width:6rem !important;
    text-align:center !important;
}
.action{
    min-width: 9.3rem !important;
    text-align:center !important;
    padding: 0px !important;
}
.activity{
    padding: 0 !important;
    text-align: center !important;
}
.prepare{
    min-width: 9.1rem !important;
    width:9.1rem;
    text-align:center !important;
}
.exportColumn{
    text-align:center !important;
    padding: 0 !important;
}
.endPeriodTime{
    min-width:7.3rem !important;
}
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: -40px auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.marginBottom{
    margin-bottom:15px;
}
.form input {
  font-family: 'Heebo', sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button {
  margin-left:0px !important;
  font-family: 'Heebo', sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #1790FF;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: bold;
}
.form button:hover,.form button:active,.form button:focus {
  background: #69CCE8;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #69CCE8;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before, .container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #EF3B3A;
}
body {
  background:white; /* fallback for old browsers */
  /* background: -webkit-linear-gradient(right, #76b852, #8DC26F);
  background: -moz-linear-gradient(right, #76b852, #8DC26F);
  background: -o-linear-gradient(right, #76b852, #8DC26F);
  background: linear-gradient(to left, #76b852, #8DC26F); */
  font-family: 'Heebo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}
/* .ant-result-subtitle{
    color:white !important;
}
.ant-result-title{
    color:white !important;
} */
.thankYouPage{
    display: block;
    text-align:center;
    font-size:1.2rem;
    color:black;
}

.thankYouPage .thankYou{
    text-align: center;
    /* font-size: x-large; */
    margin-top:2.5rem !important;
    color:black;
}

.thankYouPage .enjoy{
    text-align:center;
    color:black !important;
    font-size:2.4rem !important;

    
    /* font-size:4rem; */
}
.total{
    text-align: center;
    color : black !important;
    padding-top:3rem !important;
}

.middle{
    text-align: center;
    color : black !important;
    font-size: 1.1rem;
}

.thankYou{
    font-size: 4rem;
    color : black !important;
}

.enjoy{
    font-size:2.1rem !important;
    color : black !important;
}
@media only screen and (min-device-width: 319px) and (max-device-width: 340px) 
and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71){
  /* iPhone 5 only */
  .middleBox{
    padding-top:15rem;
    width:3rem !important;
    /* padding-top:0rem !important; */
    min-height: 1rem !important;
    max-height: 1rem;
  }
}

#app, #app>div, #root, body, html {
  width:100% !important;
  height: 100% !important;
}

body{
  width: 22rem;
  height: 34rem !important;
}

.eventContainer{
  top: 50% !important;
  margin: 0;
  left: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}


.landingPage{
  height:100%;
}
.erezLogo{
  width:22rem;
  margin:auto;
  background-color:#FFFFFF99;
  /* width: 5rem;
  bottom: 0;
  margin-left: 8.5rem !important;
  margin-right: 8.5rem !important; */
}
.erezLogo img{
  display:block;
  width:5rem;
  height:4.5rem !important;
  background-color:#FFFFFF00 !important;
  margin:auto;
}

/* background: url(https://firebasestorage.googleapis.com/v0/b/room-picker.appspot.com/o/bg_1.jpg?alt=media&token=9f64816e-922e-439e-b95c-c699610be252) no-repeat center center fixed;  */
.landingPageEvent{
  background: url(/static/media/bg_4.dc8163eb.jpg) no-repeat center center fixed;
  background-size: cover;
  height:100%;
}

/* Reminder : background image 2 taken from https://oxleytravel.com.au/the-kids-cant-wait/ */
.middleBox{
  min-height: 37.5rem !important;
  max-height: 37.5rem;
  display: block !important;
  margin: auto !important;
  width:22rem !important;
  padding-top: 2rem;
  /* border-right:1px solid black;
  border-left:1px solid black; */
  background-color:#FFFFFF99;
}

.idInput{
  width: 16rem !important;
  margin:auto !important;
  display:block !important;
  margin-top:7rem !important;
  border:1px solid black !important;
}

input[type="id"]::-webkit-input-placeholder { /* Firefox, Chrome, Opera */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
}

input[type="id"]::-moz-placeholder { /* Firefox, Chrome, Opera */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
}

input[type="id"]:-ms-input-placeholder { /* Firefox, Chrome, Opera */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
}

input[type="id"]::-ms-input-placeholder { /* Firefox, Chrome, Opera */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
}

input[type="id"]::placeholder { /* Firefox, Chrome, Opera */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
} 

input[type="email"]:-ms-input-placeholder { /* Internet Explorer 10-11 */ 
  color: black;
  text-align:center; 
  opacity: 0.5;
  font-weight: 500;
} 

input[type="email"]::-ms-input-placeholder { /* Microsoft Edge */ 
  color: black; 
  text-align:center;
  opacity: 0.5;
  font-weight: 500;
} 

.enterBtn{
  display: block !important;
  margin: auto;
  margin-top: 1rem;
}

html, body {
    height: 100% !important;
}

/* html, body, #app, #app>div, #root{
  height: 100%;
  width:100%;
} */

.appBody{
  height: 100% !important;
}

.CompanyLogo{
  margin: auto;
  display: block;
  min-height: 4rem;
  max-height: 4rem;
  width: 10rem;
}

h1.eventName{
  width: auto;
  text-align:center !important;
  color:black;
  /* font-weight: bolder; */
  margin-top:0rem !important;
  padding-top:0.5rem !important;
  margin-bottom:0rem !important;
  font-family: 'Heebo', sans-serif;
}

.thinking{
  display:block;
  margin:auto;
}
.workerSelctor{
width: 16rem;
margin: auto !important;
display: block !important;
text-align: center;
top: 1rem !important;
}

.ant-select-selection__placeholder{
text-align:center !important;
}

.workerSave{
  width: 5rem;
  margin: auto !important;
  display: block !important;
  top: 2rem !important;
}
.workerInputs{
  top:0.7rem !important;
  width: 16rem !important;
  margin: auto !important;
  display: block !important;
  text-align:center;
}
.divInputs{
  padding-bottom: 0.45rem !important;
}
.chooseRoomate{
  margin-top: -1rem;
}
.heyWorker{
  font-family: 'Heebo', sans-serif;
  text-align:center;
  color:black !important;
  margin-top:0.5rem !important;
}
.fillDetails{
  font-family: 'Heebo', sans-serif;
  font-size: 0.9rem !important;
  text-align: left;
  color: black !important;
  margin-left: 3.08rem !important;
  margin-bottom: 0px !important;
  margin-top: 0.2rem;
}
.pleaseChoose {
  margin-bottom:0rem !important;
  font-family: 'Heebo', sans-serif;
  font-size: 0.9rem !important;
  text-align: left;
  color: black !important;
  margin-left: 3.08rem !important;
}

#emailUp{
  margin-top: -0.7rem !important;
}

.titleCheckboxs{
  margin: auto !important;
  text-align: center;
  
  margin-top: 0rem !important;
}
.titleCheckboxs label{
  color:black !important;
   
}

.pickedBySomeoneElse .fillDetails{
  font-family: 'Heebo', sans-serif;
  font-size: 0.9rem !important;
  text-align: left;
  color: black !important;
  margin-left: 3.08rem !important;
  margin-bottom: -0.4rem !important;
  margin-top: 0.5rem !important;
}

.pickedBySomeoneElse .titleCheckboxs{
  margin: auto !important;
  text-align: center;
  /* margin-left: 3.1rem !important; */
  margin-top: 0rem !important;
}

.pickedBySomeoneElse .titleCheckboxs label{
  color:black !important;
}


.ant-checkbox + span {
  padding-right: 2px !important;
  padding-left: 2px !important;
}
.thinking{
  margin:auto;
  width:1rem;
  display: block;
  margin-top:19rem !important;
}
.ant-select-selection-selected-value {
  float: none !important;
}

/* for hiiden originaly inputNumber arrows  */
.ant-input-number-handler-wrap{
  visibility: hidden !important;
}

.cencelArea{
  font-size:1.4rem;
  /* color:mediumblue; */
  color:black;
  text-align:center;
  margin-top: 6rem;
}
.cencelAreaPicked{
  font-size:1.4rem;
  /* color:mediumblue; */
  color:black;
  text-align:center;
  margin-top: 1rem;
}
.cencelLine{
    display: block;
    /* display: contents; */
    font-family: 'Heebo', sans-serif;
    font-size: 1.3rem ;
    color: black ;
    font-weight: 900;
    /* position: relative; */
    margin-top: 11rem ;
}
.pickedBySomeoneElse .cencelLine{
  display: block;
  /* display: contents; */
  font-family: 'Heebo', sans-serif;
  font-size: 1.3rem ;
  color: black ;
  font-weight: 900;
  /* position: relative; */
  margin-top: 4rem ;
}

.cencelLine Button{
  top:0.4rem !important;
}

.ant-alert-with-description i{
  display: contents;
}

.ant-alert-with-description{
  text-align:center;
  top: 5rem;
}
.beenPickedText{
  margin-bottom:2rem;
}

@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3){

}





@media screen and (device-aspect-ratio: 2/3) {
 
}



/* wide screen (not mobile!) */
@media screen and (min-height: 590px) and (max-height: 650px)
              and (min-width: 750px) and (max-width: 820px) 
              {
  .middleBox{
    min-height: 33.3rem;
    max-height: 33.3rem;
    min-width: 20rem;
    max-width: 20rem;
  }
  .cencelAreaPicked {
    margin-top:0rem !important;
  }
  .beenPickedText {
    margin-bottom:0rem !important;
  }
  .pickedBySomeoneElse .cencelLine{
    margin-top:3.5rem !important;
    font-size:0.9rem !important;
  }
  .erezLogo{
    width:20rem;
    height:4.5rem;
  }
  h1.eventName {
    margin-bottom:0.5rem !important;
  }
}
/* very wide screen (not mobile!) */
@media screen and (min-height: 900px) and (max-height: 1100px)
              and (min-width: 1500px) and (max-width: 2300px) 
              {
  .middleBox{
    min-height: 55rem;
    max-height: 55rem;
    min-width: 25rem !important;
    max-width: 25rem !important;
    padding-top:5rem;
  }
  .erezLogo{
    width:25rem;
    height:10rem;
  }
}

/* iphone SE/5 */
@media screen and (min-height: 567px) and (max-height: 569px)
              and (min-width: 319px) and (max-width: 330px) 
{
  
}

@media screen and (min-height: 660px) and (max-height: 674px)
              and (min-width: 370px) and (max-width: 380px) 
{
  .middleBox{
    min-height: 35rem !important;
    padding-top:1rem !important;
  }
}


@media screen and (min-height: 668px) and (max-height: 690px)
              and (min-width: 668px) and (max-width: 2500px) 
{
  .middleBox{
    min-height:33rem !important;
  }
}
@media screen and (min-height: 0px) and (max-height: 650px)
{
  .middleBox{
    min-height:25rem !important;
  }
  .workerSave{
    margin-top:-1rem !important;
  }
  .pickedBySomeoneElse .cencelLine{
    margin-top:2.5rem !important;
    font-size: 0.8rem !important;
  }
  .cencelAreaPicked {
    margin-top:0rem !important;
    height:20.5rem !important;
  }
  .beenPickedText {
    margin-bottom:0rem !important;
    font-size:1.1rem !important;
  }
  .CompanyLogo{
    min-height: 2rem !important;
    max-height: 3rem !important;
    width: 8rem !important;
  }
  .erezLogo img {
    height:3.5rem !important;
    width:3.5rem !important;
  }
}
/* @media screen and (min-width: 350px) and (max-width: 2500px)
  {
  #app, #app>div, #root, body, html {
    width:100% !important;
    height: 100% !important;
  }
} */
.Admin-add-event h1{
    text-align:center;
}

.Add-event-body{
    width:34rem;
    margin:auto;
}

.Add-event-body h1{
    padding-top:0.5rem;
}

.submitItem {
    text-align: center;
    width:100% !important;
}

.submitItem .ant-col{
    margin: auto;
    width: 100%;
}

.ant-row{
    display: flex;
}

.dropbox{
    height: 12rem;
    margin: auto;
    width: 35rem !important;
}    

/* .draggersExcel .ant-col.ant-col-14.ant-form-item-control-wrapper{
    width:100%;
}


.dropbox .ant-form-item-control-wrapper{
    width:100% !important;
} */

.draggersExcel{
    height: 11rem !important;
    width: 15rem !important;
    float:right;
    margin-left:1rem !important;
    margin-right:1rem !important;
    margin-top:1rem !important;
}

.dateAndLogo{
    display: flex;
    margin: auto;
    width: 100%;
}

.datePicker{
    margin:auto !important;
}

.ant-upload.ant-upload-select-picture-card{
    margin-top: 0.8rem !important;
}

.draggersImage{
    margin: auto !important;
    margin-right: -3rem !important;
    
}

.roomTypeInputs input{
    width:12rem;
    margin:1.3rem;
}

.roomTypeInputs{
    margin: auto;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.BtnContainer button{
    margin:auto;
    width:20%;
}

/* .draggersImage .ant-col.ant-col-14.ant-form-item-control-wrapper{
    margin:auto;
    padding-left: 2rem;
    
}
.draggersImage .ant-upload{
    height: 9.4rem !important;

     */



