.Admin-add-event h1{
    text-align:center;
}

.Add-event-body{
    width:34rem;
    margin:auto;
}

.Add-event-body h1{
    padding-top:0.5rem;
}

.submitItem {
    text-align: center;
    width:100% !important;
}

.submitItem .ant-col{
    margin: auto;
    width: 100%;
}

.ant-row{
    display: flex;
}

.dropbox{
    height: 12rem;
    margin: auto;
    width: 35rem !important;
}    

/* .draggersExcel .ant-col.ant-col-14.ant-form-item-control-wrapper{
    width:100%;
}


.dropbox .ant-form-item-control-wrapper{
    width:100% !important;
} */

.draggersExcel{
    height: 11rem !important;
    width: 15rem !important;
    float:right;
    margin-left:1rem !important;
    margin-right:1rem !important;
    margin-top:1rem !important;
}

.dateAndLogo{
    display: flex;
    margin: auto;
    width: 100%;
}

.datePicker{
    margin:auto !important;
}

.ant-upload.ant-upload-select-picture-card{
    margin-top: 0.8rem !important;
}

.draggersImage{
    margin: auto !important;
    margin-right: -3rem !important;
    
}

.roomTypeInputs input{
    width:12rem;
    margin:1.3rem;
}

.roomTypeInputs{
    margin: auto;
    display: block;
    width: fit-content;
}

.BtnContainer button{
    margin:auto;
    width:20%;
}

/* .draggersImage .ant-col.ant-col-14.ant-form-item-control-wrapper{
    margin:auto;
    padding-left: 2rem;
    
}
.draggersImage .ant-upload{
    height: 9.4rem !important;

     */


