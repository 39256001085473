.total{
    text-align: center;
    color : black !important;
    padding-top:3rem !important;
}

.middle{
    text-align: center;
    color : black !important;
    font-size: 1.1rem;
}

.thankYou{
    font-size: 4rem;
    color : black !important;
}

.enjoy{
    font-size:2.1rem !important;
    color : black !important;
}